import React, { useRef, useState } from 'react'
import useWindowSize from '@utils/useWindowSize'
import { Head, Button, FAQ } from '@components'
import * as st from '@assets/styl/Help.module.styl'
import cn from 'classnames'

export default function Help() {
  const vs = useWindowSize()
  const width = vs.width || 0

  return (
    <>
      <Head path="/ajuda" />

      <section className={st.header}>
        <div>
          <h2>Tire suas dúvidas sobre o carregamento na rede WeCharge</h2>
          {width < 750 && (
            <Button
              to={`https://whatsa.me/5508002910045/?t=Ol%C3%A1,%20preciso%20de%20ajuda.`}
              external
              className={st.whatsapp}
            >
              <span className="icon-whatsapp"></span>
              Fale com nosso suporte
            </Button>
          )}
          {width >= 750 && <Accordion />}
          <p className={st.download}>
            <span>Baixe o App WeCharge</span>
            <a
              href="https://apps.apple.com/br/app/wecharge/id1595933153"
              target="_blank"
              className={st.ios}
            ></a>
            <a
              href="https://play.google.com/store/apps/details?id=br.com.voltbras.wecharge&hl=pt"
              target="_blank"
              className={st.android}
            ></a>
          </p>
          {width < 750 && <Accordion />}
          {width < 750 && (
            <Button
              to="https://www.wechargeshop.com.br/"
              external
              className={st.buy}
              color="white"
            >
              Compre seu carregador
            </Button>
          )}
          <div className={st.phones}>
            {width >= 750 && (
              <Button
                to={`https://whatsa.me/5508002910045/?t=Ol%C3%A1,%20preciso%20de%20ajuda.`}
                external
                className={st.whatsapp}
              >
                <span className="icon-whatsapp"></span>
                Fale com nosso suporte
              </Button>
            )}
            <p>
              {width >= 750 ? 'Ou ligue' : 'Suporte WeCharge'}
              <strong>0800 291 0045</strong>
              {width < 750 && 'sac@wecharge.com.br'}
            </p>
          </div>
          {width >= 750 && (
            <Button
              to="https://www.wechargeshop.com.br/"
              external
              className={st.buy}
              color="white"
            >
              Compre seu carregador
            </Button>
          )}
        </div>
      </section>

      <section className={st.questions}>
        <div className={st.container}>
          <h3>Principais dúvidas sobre o carregamento</h3>
          <ul>
            <li>
              <h4>Não consigo tirar o plug do carro, o que eu faço?</h4>
              <p>
                Tente travar e destravar o carro e em seguida tente retirar o
                plugue. Se ainda assim não conseguir, clique no botão acima de
                "Fale com nosso suporte".
              </p>
            </li>
            <li>
              <h4>Como realizar o pagamento das recargas no App?</h4>
              <p>
                Como realizar o pagamento das recargas no app? Você pode efetuar
                pagamentos adicionando um cartão de crédito ou saldo via PIX.
              </p>
            </li>
            <li>
              <h4>Já tenho o App EDP, preciso instalar o App WeCharge?</h4>
              <p>
                Sim! Recomendamos instalar o App WeCharge pois em breve ele será
                o único App para usar os eletropostos da EDP.
              </p>
            </li>
          </ul>
        </div>
      </section>
      <FAQ pageHelp/>
    </>
  )
}

function Accordion() {
  const [open, setOpen] = useState(false)
  const [height, setHeight] = useState(-1)
  const ref = useRef<HTMLUListElement>(null)

  return (
    <div className={cn(st.accordion, { [st.open]: open })}>
      <h3
        onClick={() => {
          setOpen(!open)
          if (!open) {
            setHeight(ref.current.offsetHeight)
            const interval = setInterval(() => {
              setHeight(0)
              clearInterval(interval)
            }, 200)
          } else {
            setHeight(ref.current.offsetHeight)
            const interval = setInterval(() => {
              setHeight(-1)
              clearInterval(interval)
            }, 20)
          }
        }}
      >
        Como usar o carregador<span></span>
      </h3>
      <div style={height === -1 ? {} : { height: height || 'auto' }}>
        <ul ref={ref}>
          <li>
            <p>Baixe o App WeCharge e cadastre-se.</p>
          </li>
          <li>
            <p>Escaneie o QR Code ou digite o código do carregador.</p>
          </li>
          <li>
            <p>No App, selecione o plug e desbloqueie o carregador.</p>
          </li>
          <li>
            <p>Plugue o carregador no carro e acompanhe a recarga pelo App.</p>
          </li>
          <li>
            <p>
              Finalize a recarga pelo App e{' '}
              <strong>destrave o carro para retirar o plug</strong>.
            </p>
          </li>
        </ul>
      </div>
    </div>
  )
}
